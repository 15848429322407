module.exports = [{
      plugin: require('../../shared/gatsby/plugins/gatsby-plugin-privacy-and-consent/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg viewBox=\"0 0 24 24\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"M10.8786797,6.05025253 L15,1.92893219 C16.7967298,0.132202428 19.6206785,-0.0112433245 21.5814287,1.49859493 C21.7515515,1.62959474 21.9151761,1.77304049 22.0710678,1.92893219 C24.0236893,3.88155365 24.0236893,7.04737854 22.0710678,9 L17.9497475,13.1213203 C17.5592232,13.5118446 16.9260582,13.5118446 16.5355339,13.1213203 C16.1450096,12.7307961 16.1450096,12.0976311 16.5355339,11.7071068 L20.6568542,7.58578644 C21.8284271,6.41421356 21.8284271,4.51471863 20.6568542,3.34314575 C19.4852814,2.17157288 17.5857864,2.17157288 16.4142136,3.34314575 L12.2928932,7.46446609 C11.9023689,7.85499039 11.2692039,7.85499039 10.8786797,7.46446609 C10.4881554,7.0739418 10.4881554,6.44077682 10.8786797,6.05025253 Z M13.1213203,17.9497475 L9,22.0710678 C7.04737854,24.0236893 3.88155365,24.0236893 1.92893219,22.0710678 C-0.0236892706,20.1184464 -0.0236892706,16.9526215 1.92893219,15 L6.05025253,10.8786797 C6.44077682,10.4881554 7.0739418,10.4881554 7.46446609,10.8786797 C7.85499039,11.2692039 7.85499039,11.9023689 7.46446609,12.2928932 L3.34314575,16.4142136 C2.17157288,17.5857864 2.17157288,19.4852814 3.34314575,20.6568542 C4.51471863,21.8284271 6.41421356,21.8284271 7.58578644,20.6568542 L11.7071068,16.5355339 C12.0976311,16.1450096 12.7307961,16.1450096 13.1213203,16.5355339 C13.5118446,16.9260582 13.5118446,17.5592232 13.1213203,17.9497475 Z M4.75735931,17.8284271 L17.8284271,4.75735931 C18.2189514,4.36683502 18.8521164,4.36683502 19.2426407,4.75735931 C19.633165,5.1478836 19.633165,5.78104858 19.2426407,6.17157288 L6.17157288,19.2426407 C5.78104858,19.633165 5.1478836,19.633165 4.75735931,19.2426407 C4.36683502,18.8521164 4.36683502,18.2189514 4.75735931,17.8284271 Z\" fill=\"currentColor\">\n  </path>\n</svg>\n","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Product Blog &bull; Sentry","short_name":"Sentry Blog","start_url":"/","background_color":"#584674","theme_color":"#584674","display":"minimal-ui","icon":"../shared/images/sentry-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4125227708a59b8e7f7c3321cb0bb306"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
